import { useNavigation, useRoute } from "@react-navigation/native";
import { useAuthService, useErrorService } from "@src/ducks/hooks";
import SplashWOProgress from 'components/splash/splash-wo-progress';
import React from "react";
import { palmsbetVerifyAccount } from "utils/api";
import { useFetchPost } from "utils/api/hooks";

const PalmsSigningVerification = () => {
  const route = useRoute<any>();
  const navigation = useNavigation<any>();
  const { onLoginSuccess } = useAuthService();
  const { runCallbackRequest } = useFetchPost();
  const { onSetErrorMessage } = useErrorService();

  React.useEffect(() => {
    const params = {
      "account": route.params?.account,
      "accountCurrency": route.params?.account_currency,
      "cs": route.params?.cs,
      // "ipAddress": route.params?.account,
      "isReal": route.params?.is_real,
      "language": route.params?.language,
      "launchedAtUtc": route.params?.launched_at_utc,
      "platform": route.params?.account,
      "session": route.params?.session,
      "version": route.params?.version
    };

    runCallbackRequest(
      () => palmsbetVerifyAccount(params),
      (response) => {
        onLoginSuccess(response);
      },
      (error) => {
        console.log("errorerror", error)
        if(error.message){
          onSetErrorMessage(error?.message);
          navigation.navigate("SignInOption")
        }
      }
    );
  }, [route.params]);

  return <SplashWOProgress />
};

export default PalmsSigningVerification;
