import _ from "lodash";

const sapphire = ["type4", "big", "type2", "medium", "type2", "big"];
const emerald = ["type4", "big", "type2", "medium", "type2", "big"];
const ruby = ["big", "type4", "big", "type2", "medium", "type2"];
const diamond = ["medium", "type4", "big", "type2", "medium", "type2"];
const arrayStone = [sapphire, emerald, ruby, diamond];

const groupAndSort = (arr: any) => {
  return new Promise((resolve, reject) => {
    const groupedData: any = _.cloneDeep(arr);
    const promises: any = [];

    _.forEach(groupedData, (groupObjects, index: number) => {
      const promise = new Promise((innerResolve) => {
        const transformedObjects = TransformGameList(
          groupObjects.games,
          arrayStone[index]
        );
        innerResolve(transformedObjects);
      });

      promises.push(promise);
    });

    Promise.all(promises)
      .then((sortedGroups) => {
        resolve(sortedGroups);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const TransformGameList = (inputArray: any[], sequence: any) => {
  const oldGames = [...inputArray];
  const outputArray: any = [];
  const gameTypes = [5, 6, 7];
  let currentIndex = 0;

  while (oldGames.length > 0) {
    const type = sequence[currentIndex];

    if (type === "type4") {
      const chunkSize = Math.min(4, oldGames.length);
      const chunk = oldGames.splice(0, chunkSize);

      if (chunk.length < 4) {
        chunk.forEach((item) => {
          outputArray.push({
            ...item,
            type: "medium",
          });
        });
        continue;
      }

      const findResult = chunk.find((item) =>
        gameTypes.includes(item.gameTypeId)
      );

      if (findResult) {
        chunk.forEach((item) => {
          if (gameTypes.includes(item.gameTypeId)) {
            outputArray.push({
              ...item,
              type: "medium",
            });
          } else {
            oldGames.unshift(item);
          }
        });

        currentIndex = sequence.indexOf("type4") - 1;
      } else {
        outputArray.push({
          type,
          child: chunk,
        });
      }
    } else if (type === "type2") {
      const chunkSize = Math.min(2, oldGames.length);
      const chunk = oldGames.splice(0, chunkSize);

      if (chunk.length < 2) {
        chunk.forEach((item) => {
          outputArray.push({
            ...item,
            type: "medium",
          });
        });
        continue;
      }

      const findResult = chunk.find((item) =>
        gameTypes.includes(item.gameTypeId)
      );

      if (findResult) {
        chunk.forEach((item) => {
          if (gameTypes.includes(item.gameTypeId)) {
            outputArray.push({
              ...item,
              type: "medium",
            });
          } else {
            oldGames.unshift(item);
          }
        });
        currentIndex = sequence.indexOf("type2") - 1;
      } else {
        outputArray.push({
          type,
          child: chunk,
        });
      }
    } else {
      const item = oldGames.shift();
      outputArray.push({
        ...item,
        type: gameTypes.includes(item.gameTypeId) ? "medium" : type,
      });
    }

    currentIndex = (currentIndex + 1) % sequence.length; // Repeat the type sequence
  }

  return outputArray;
};

export const compileTransformation = async (inputArray: any) => {
  const groupAndSortResult: any = await groupAndSort(inputArray);

  const labels = [
    {
      label: "TIER 1",
      source: "tier_bronze",
    },
    {
      label: "TIER 2",
      source: "tier_bronze",
    },
    {
      label: "TIER 3",
      source: "tier_silver",
    },
    {
      label: "TIER 4",
      source: "tier_gold",
    },
  ];

  const combinedArray = _.flatMap(
    groupAndSortResult,
    (group: any, index: number) => {
      const label = labels[index];

      if (index > 0) {
        return [label, ...group];
      } else {
        return group;
      }
    }
  );

  return combinedArray;
};

export const goldGamesFilter = (arr: any) => {
  const goldGames = _.chain(arr)
    .filter((item) => item.isGoldAllow === 1)
    .value();

  return goldGames;
};

// SWITCH GAMES BETWEEN FAVORITE/UNFAVORITE
export const switchSelectedFavoriteGames = (games: any, id: number) => {

  return games.map((game: any) => {
    if (game.child) {
      // Handle nested items
      return {
        ...game,
        child: game.child.map((item: any) => {
          if (item.id === id) {
            return { ...item, isFavourite: !item.isFavourite };
          }
          return item;
        })
      };
    } else if (game.id === id) {
      // Handle top-level items
      return { ...game, isFavourite: !game.isFavourite};
    }
    return game;
  });
};

// REPLACE STRING IN JSON/ARRAY FILE
export const replaceStringInJson = (jsonObj: any, oldString: string, newString: string)  =>{
  // Check if jsonObj is an object
  if (typeof jsonObj !== "object" || jsonObj === null) {
    return jsonObj;
  }

  // If jsonObj is an array, iterate through each element
  if (Array.isArray(jsonObj)) {
    for (let i = 0; i < jsonObj.length; i++) {
      jsonObj[i] = replaceStringInJson(jsonObj[i], oldString, newString);
    }
  } else {
    // If jsonObj is an object, iterate through each key-value pair
    for (const key in jsonObj) {
      // eslint-disable-next-line no-prototype-builtins
      if (jsonObj.hasOwnProperty(key)) {
        // Recursively call replaceStringInJson for nested objects/arrays
        jsonObj[key] = replaceStringInJson(jsonObj[key], oldString, newString);

        // Check if the current value is a string and replace the oldString
        if (typeof jsonObj[key] === "string") {
          jsonObj[key] = jsonObj[key].replace(new RegExp(oldString, "g"), newString);
        }
      }
    }
  }

  return jsonObj;
};

// COMPARE TWO ARRAYS TO CHECK IF THE MISSION IS COMPLETED
export const compareMissionsBeforeAndAfter = (incompleteArray: any, completedArray: any) => {
  // Create a set of completed ids for faster lookup
  const completedIdSet = new Set(completedArray.map((item: any) => item.id));

  // Map over the incompleteArray and add isNewlyCompleted property
  const resultArray = incompleteArray.map((item: any) => {
    if (completedIdSet.has(item.id) && completedArray.find((completedItem: any) => completedItem.id === item.id && completedItem.status === "COMPLETED" && item.status === "ACTIVE")) {
      // If the id is found in the completedArray, and there is a status transition, add isNewlyCompleted property and adapt other properties
      const completedItem = completedArray.find((completedItem: any) => completedItem.id === item.id);
      return { ...item, hasKey: true, ...completedItem };
    } else {
      // If the id is not found in the completedArray or there is no status transition, keep the object unchanged
      return item;
    }
  });

  return resultArray;
};

// FILTER PRODUCTS
export const filterShopProducts = (data: any[] = []) => {
  return data
    .filter(item => item.isTopUp === true && item.isActive === 1)
    .sort((a, b) => a.description.localeCompare(b.description));
};
