import images from "@assets/images";
import Icon from "@expo/vector-icons/Ionicons";
import { useNavigation } from "@react-navigation/native";
import { DimensionContext } from "@src/DimensionProvider";
import { useSettingsService } from "@src/ducks/hooks";
import { colors } from "@src/theme";
import { ImageButton } from "components/button";
import React from "react";
import { Image, TouchableOpacity, View } from "react-native";
import Logout from "./Logout";
import Sound from "./Sound";
import useStyles from "./styles.css";
import TextButton from "./text-button";

const Settings = ({ style, onBack }: any) => {
  const styles = useStyles();
  const navigation = useNavigation<any>();
  const { onChangeSettings } = useSettingsService();
  const { hudHeight, baseWidth, baseHeight } = React.useContext(DimensionContext);
  const size = React.useMemo(() => {
    return {
      width: baseWidth * 0.189,
      height: baseHeight * 0.82,
      top: hudHeight * 0.9
    };
  },[hudHeight, baseHeight, baseWidth]);

  return (
    <View style={[styles.container, size, style]}>
      <Image style={styles.i_bg_settings}
        source={{uri: images["bg-settings"]}}
        resizeMode="stretch" />
      <View style={styles.v_settings}>
        <View style={{height: size.height * 0.14}} />
        <Sound baseWidth={size.width} baseHeight={size.height} />
        <View style={{height: size.height * 0.04}} />
        <View style={{height: size.height * 0.024}} />
        <ImageButton 
          style={{width: "80%", height: "9%"}}
          onPress={() => navigation.navigate("TierLevel")}
          source={{uri: images["btn-tier-level-rewards"]}}
        />
        <View style={{height: size.height * 0.03}} />
        <TextButton
          onPress={() => navigation.navigate("TermsCondition")} 
          style={{fontSize: size.width * 0.052}}
          label="Общи условия" />
        <View style={{height: size.height * 0.03}} />
        <TextButton
          onPress={() => navigation.navigate("PrivacyPolicy")} 
          style={{fontSize: size.width * 0.052}}
          label="Политики за сигурност" />
        <View style={{height: size.height * 0.03}} />
        <TextButton
          onPress={() => navigation.navigate("Disclaimer")}
          style={{fontSize: size.width * 0.052}}
          label="Възражение" />
        <View style={{height: size.height * 0.04}} />
        <Logout baseWidth={size.width} baseHeight={size.height} />
        <TouchableOpacity
          onPress={() => (onBack ? onBack() : onChangeSettings(false))}
          style={styles.btn_back} >
          <Icon size={size.width * 0.11} color={colors.blue5} name="chevron-down-outline" />
        </TouchableOpacity>
      </View>
      <View style={styles.v_bottom} />
    </View>
  );
};

export default React.memo(Settings);
