import { SagaIterator } from "@redux-saga/core";
import qs from "qs";
import { call, put, select, takeEvery } from "redux-saga/effects";
// Types

// API
import {
  check3hoursReward,
  checkDailyReward,
  createMyFavoriteGames,
  deleteMyFavoriteGames,
  fetchAvatarTier,
  fetchFavouriteGames,
  fetchFeaturesGames,
  fetchGames,
  fetchMissions,
  fetchNotifications,
  fetchProducts,
  fetchRandomRewards,
  fetchTierUpgradeBonuses,
  fetchUserDetails,
  jadeEggAccumulation,
  palmsPointBalance,
  walletBalance
} from "@src/utils/api";

// Slice
import { compileTransformation, switchSelectedFavoriteGames } from "utils/transform-helper";
import { authActions, selectedAuthToken } from "../slices/auth.slice";
import { gamesActions, selectedFetchGames, selectedGameCategoryID, selectedGoldAllowStatus } from "../slices/games.slice";
import { lobbyActions } from "../slices/lobby.slice";
import { tierActions } from "../slices/tier.slice";
import { selectedUserUserID, userActions } from "../slices/user.slice";

function* handleLobbyRequest(): SagaIterator {
  try {
    const accessToken = yield select(selectedAuthToken);

    const userDetails = yield call(fetchUserDetails, accessToken);

    yield put(userActions.fetchUserDetails(userDetails));
    const userId = userDetails.id;

    const jadeEgg = yield call(jadeEggAccumulation, {userId}, accessToken);
    yield put(lobbyActions.jadeAmount(jadeEgg.data.amount));

    const randomRewards = yield call(fetchRandomRewards, {userId}, accessToken);
    yield put(lobbyActions.randomRewards(randomRewards.data));

    const missions = yield call(fetchMissions, { userId }, accessToken);
    yield put(lobbyActions.missions(missions.data));

    const avatarTier = yield call(fetchAvatarTier, { userId }, accessToken);
    yield put(lobbyActions.avatarTier(avatarTier));

    const featureGame = yield call(fetchFeaturesGames, "", accessToken);
    yield put(gamesActions.fetchedFeatureGames(featureGame.items || []));

    const gamesOrigin = yield call(fetchGames, "", accessToken);
    const gamesTransform = yield call(compileTransformation, gamesOrigin?.tiers ?? []);
    yield put(gamesActions.fetchedGames(gamesTransform));

    const serverTime = yield call(checkDailyReward, { userId }, accessToken);
    yield put(lobbyActions.serverTime(serverTime));

    const hourlyReward = yield call(check3hoursReward, { userId }, accessToken);
    yield put(lobbyActions.hourlyReward(hourlyReward));

    const notifications = yield call(fetchNotifications, { userId }, accessToken);
    yield put(lobbyActions.notifications(notifications.data));

    const products = yield call(fetchProducts, accessToken);
    yield put(lobbyActions.products(products));

    const tierUpgradeBonuses = yield call(fetchTierUpgradeBonuses, { userId }, accessToken);
    yield put(tierActions.tierUpgradeBonuses(tierUpgradeBonuses.content));

    yield put(lobbyActions.lobbySuccess());
    
    const ppresult = yield call(palmsPointBalance, accessToken);
    yield put(userActions.updatePalmsPointBalance(ppresult.palmsPointBalance));
  } catch (error: any) {
    if(error.status === 401 && error.error === "Unauthorized"){
      yield put(authActions.logout());
      yield put(authActions.setErrorMessage("Сесията е изтекла"));
    }
    yield put(lobbyActions.lobbySuccess());
    yield put(lobbyActions.lobbyFailure(error?.error ?? error));
  }
}

function* handleBalanceRequest(): SagaIterator {
  try {
    const userId = yield select(selectedUserUserID);
    const accessToken = yield select(selectedAuthToken);
    const balance = yield call(walletBalance, { userId }, accessToken);
    yield put(userActions.updateWallet(balance.data));
    const ppresult = yield call(palmsPointBalance, accessToken);
    yield put(userActions.updatePalmsPointBalance(ppresult.palmsPointBalance));
  } catch (error: any) {
    if(error.status === 401 && error.error === "Unauthorized"){
      yield put(authActions.logout());
      yield put(authActions.setErrorMessage("Сесията е изтекла"));
    }
  }
}

function* handleGoldenBallAmountRequest(): SagaIterator {
  try {
    const userId = yield select(selectedUserUserID);
    const accessToken = yield select(selectedAuthToken);
    const jadeEgg = yield call(jadeEggAccumulation, {userId}, accessToken);
    yield put(lobbyActions.jadeAmount(jadeEgg.data.amount));
  } catch (error: any) {
    if(error.status === 401 && error.error === "Unauthorized"){
      yield put(authActions.logout());
      yield put(authActions.setErrorMessage("Сесията е изтекла"));
    }
  }
}

function* handleTierUpgradeRequest(): SagaIterator {
  try {
    const userId = yield select(selectedUserUserID);
    const accessToken = yield select(selectedAuthToken);
    const tierUpgradeBonuses = yield call(fetchTierUpgradeBonuses, { userId }, accessToken);
    yield put(tierActions.tierUpgradeBonuses(tierUpgradeBonuses.content));
  } catch (error: any) {
    if(error.status === 401 && error.error === "Unauthorized"){
      yield put(authActions.logout());
      yield put(authActions.setErrorMessage("Сесията е изтекла"));
    }
  }
}

function* handleFetchingGameByCategory(action: {
  type: typeof gamesActions.fetchingGames;
  payload: number | string;
}): SagaIterator {
  try {
    const accessToken = yield select(selectedAuthToken);
    const isGoldAllow = yield select(selectedGoldAllowStatus);
    const params: any = {};

    if(isGoldAllow){
      params.isGoldAllow = 1;
    }

    if(action.payload === 0){
      const convertToString = qs.stringify(params);
      const favGames = yield call(fetchFavouriteGames, convertToString, accessToken);
      const newArray = [ { games: favGames.items } ];
      const gamesTransform = yield call(compileTransformation, newArray);
      yield put(gamesActions.fetchedGames(gamesTransform));
      return;
    }

    params.gameCategoryId = action.payload;

    if(action.payload === -1){
      delete params.gameCategoryId;
    }

    const convertToString = qs.stringify(params);
    const gamesOrigin = yield call(fetchGames, convertToString, accessToken);
    const gamesTransform = yield call(compileTransformation, gamesOrigin?.tiers ?? []);
    yield put(gamesActions.fetchedGames(gamesTransform));
  } catch (error: any) {
    if(error.status === 401 && error.error === "Unauthorized"){
      yield put(authActions.logout());
      yield put(authActions.setErrorMessage("Сесията е изтекла"));
    }
    yield put(gamesActions.fetchingGamesFailed(error?.error ?? error));
  }
}

function* handleFetchingGameByGoldCurrency(action: {
  type: typeof gamesActions.fetchGoldGames;
  payload: number | string;
}): SagaIterator {
  try {
    const accessToken = yield select(selectedAuthToken);
    const gameCategoryId = yield select(selectedGameCategoryID);
    const params: any = {};
    if(action.payload){
      params.isGoldAllow = 1;
    }

    if(gameCategoryId === 0){
      const convertToString = qs.stringify(params);
      const favGames = yield call(fetchFavouriteGames, convertToString, accessToken);
      const newArray = [ { games: favGames.items } ];
      const gamesTransform = yield call(compileTransformation, newArray);
      yield put(gamesActions.fetchedGames(gamesTransform));
      return;
    }

    params.gameCategoryId = gameCategoryId;

    if(gameCategoryId === -1){
      delete params.gameCategoryId;
    }

    const convertToString = qs.stringify(params);
    const gamesOrigin = yield call(fetchGames, convertToString, accessToken);
    const gamesTransform = yield call(compileTransformation, gamesOrigin?.tiers ?? []);
    yield put(gamesActions.fetchedGames(gamesTransform));
  } catch (error: any) {
    if(error.status === 401 && error.error === "Unauthorized"){
      yield put(authActions.logout());
      yield put(authActions.setErrorMessage("Сесията е изтекла"));
    }
    yield put(gamesActions.fetchingGamesFailed(error?.error ?? error));
  }
}

function* handleChangeFavoriteGames(action: {
  type: typeof gamesActions.fetchFavouriteGames;
  payload: any;
}): SagaIterator {
  try {
    const token = yield select(selectedAuthToken);
    const games = yield select(selectedFetchGames);
    const userId = yield select(selectedUserUserID);
    const accessToken = yield select(selectedAuthToken);
    const gameCategory = yield select(selectedGameCategoryID);
    const isGoldAllow = yield select(selectedGoldAllowStatus);
    const updatedGames = yield call(switchSelectedFavoriteGames, games, action.payload.id);
    yield put(gamesActions.fetchedGames(updatedGames));

    if(action.payload.isFavourite){
      yield call(deleteMyFavoriteGames, {
        "gameId": action.payload.id,
        "userId": userId
      },token);
      if(gameCategory === 0){
        const params: any = {};

        if(isGoldAllow){
          params.isGoldAllow = 1;
        }

        const convertToString = qs.stringify(params);
        const favGames = yield call(fetchFavouriteGames, convertToString, accessToken);
        const newArray = [ { games: favGames.items } ];
        const gamesTransform = yield call(compileTransformation, newArray);
        yield put(gamesActions.fetchedGames(gamesTransform));
      }
    }else {  
      yield call(createMyFavoriteGames, {
        "gameId": action.payload.id,
        "userId": userId
      }, token);
    }
  } catch (error: any) {

    console.log("handleChangeFavoriteGames", error);
    // empty
  }
}

// Watcher Saga
function* lobbyWatcherSaga(): SagaIterator {
  yield takeEvery(lobbyActions.lobbyRequest.type, handleLobbyRequest);
  yield takeEvery(lobbyActions.balanceRequest.type, handleBalanceRequest);
  yield takeEvery(gamesActions.fetchingGames.type, handleFetchingGameByCategory);
  yield takeEvery(gamesActions.fetchGoldGames.type, handleFetchingGameByGoldCurrency);
  yield takeEvery(gamesActions.fetchFavouriteGames.type, handleChangeFavoriteGames);
  yield takeEvery(lobbyActions.checkTierRequest.type, handleTierUpgradeRequest);
  yield takeEvery(lobbyActions.checkGoldenBallAmount.type, handleGoldenBallAmountRequest);
}

export default lobbyWatcherSaga;
