import images from "@assets/images";
import { useAppSelector } from "@src/ducks/ducksHook";
import { selectedFetchGamesCompleted, selectedFetchingGames } from "@src/ducks/slices/games.slice";
import { selectedLobbyLoading, selectedLobbySuccess } from "@src/ducks/slices/lobby.slice";
import { Loading02 } from "components/loading";
import React from "react";
import { Image, InteractionManager, View, useWindowDimensions } from "react-native";
import Animated, {
  Extrapolation,
  interpolate,
  runOnJS,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from "react-native-reanimated";
import colors from "theme/colors";
import { HomeContext } from "../main/HomeProvider";
import useStyles from "./styles.css";

const CurtainDialog = () => {
  const styles = useStyles();
  const animated = useSharedValue(1);
  const { width} = useWindowDimensions();
  const [visible, setVisible] = React.useState(false);
  const isLoading = useAppSelector(selectedLobbyLoading);
  const lobbySuccess = useAppSelector(selectedLobbySuccess);
  const fetchingGames = useAppSelector(selectedFetchingGames);
  const fetchGamesCompleted = useAppSelector(selectedFetchGamesCompleted);

  const onClose = () => {
    setVisible(false);
  };

  const handleCurtainOpening = (duration: number) => {
    setVisible(true);
    animated.value = withTiming(1, { duration },(finished) => {
      if (finished) runOnJS(handleCurtainClosing)();
    });
  };

  const handleCurtainClosing = () => {
    animated.value = withTiming(0, { duration: 1000 },(finished) => {
      if (finished) runOnJS(onClose)();
    });
  };

  const animateStyle = useAnimatedStyle(() => {
    const translateX = interpolate(
      animated.value,
      [0, 1],
      [-width / 2, 0],
      Extrapolation.CLAMP
    );

    return {
      transform: [{translateX}]
    };
  }, []);

  const animateStyle2 = useAnimatedStyle(() => {
    const translateX = interpolate(
      animated.value,
      [0, 1],
      [width / 2, 0],
      Extrapolation.CLAMP
    );

    return {
      transform: [{translateX}]
    };
  }, []);

  React.useEffect(() => {
    if(isLoading){
      animated.value = 1;
      setVisible(true);
    }

    InteractionManager.runAfterInteractions(() => {
      if(lobbySuccess){
        handleCurtainClosing();
      }
    });
  }, [isLoading, lobbySuccess]);

  React.useEffect(() => {
    if(fetchingGames){
      animated.value = 1;
      setVisible(true);
    }
  }, [fetchingGames]);

  React.useEffect(() => {
    InteractionManager.runAfterInteractions(() => {
      if(fetchGamesCompleted){
        handleCurtainClosing();
      }
    });
  }, [fetchGamesCompleted]);

  if(!visible) return null;

  return (    
    <View style={styles.container}>
      <Animated.View  style={[{ width: width / 2, height: "100%", }, animateStyle]}>
        <Image
          style={styles.image_style}
          source={{uri: images.curtain}} resizeMode="stretch" />
      </Animated.View>
      <Animated.View  style={[{ width: width / 2, height: "100%" }, animateStyle2]}>
        <Image
          style={styles.image_style_right}
          source={{uri: images.curtain}} resizeMode="stretch" />
      </Animated.View>
      {isLoading && <Loading02 style={styles.loading_style} color={colors.white} />}
    </View>
  );
};

export default CurtainDialog;
